<template>
  <div v-if="!$vuetify.breakpoint.smAndDown">
    <v-navigation-drawer
      v-if="$store.getters['auth/isLoggedIn']"
      id="core-navigation-drawer"
      mobile-break-point="960"
      app
      dark
      :mini-variant.sync="drawer"
      v-model="hover"
      class="primary"
      width="170"
      v-bind="$attrs"
    >
      <!-- <v-img src="@/assets/background-invert.png" height="100%" position="left">-->
      <v-list-item>
        <v-list-item-content class="pa-0 my-8 mx-2" v-if="!drawer">
          <img src="@/assets/logo.png" width="100%" />
        </v-list-item-content>
        <v-list-item-avatar v-else style="margin-left: -10px">
          <v-avatar>
            <img src="@/assets/logo.svg" alt="John" style="margin-left: 10px" />
          </v-avatar>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item
        v-if="!drawer && users.length > 1 && false"
        class="no-wrap px-0 mx-2"
        >{{
          $store.getters["auth/isStudio"]
            ? user.studio.studio_name
            : `${user.first_name} ${user.last_name}`
        }}</v-list-item
      >

      <br v-if="$vuetify.breakpoint.mdAndUp || true" />
      <v-list expand dense nav class="pa-0 ma-0 list" style="top: 0">
        <!-- Style cascading bug  -->
        <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
        <div />

        <template v-for="(item, i) in computedItems">
          <base-item
            style=""
            class="ma-1"
            :key="`item-${i}`"
            :item="item"
          ></base-item>
        </template>

        <!-- Style cascading bug  -->
        <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
        <div />
      </v-list>
      <v-list-item v-if="!$vuetify.breakpoint.smAndUp">
        <div>
          <a
            v-if="$store.getters['auth/rrss'].hasOwnProperty('instagram')"
            :href="`https://www.instagram.com/${$store.getters['auth/rrss'].instagram}/`"
            target="blank"
            ><v-btn fab width="10" height="30" color="primary" depressed
              ><v-icon>mdi-instagram</v-icon></v-btn
            ></a
          >
          <a
            v-if="$store.getters['auth/rrss'].hasOwnProperty('twitter')"
            :href="`https://www.twitter.com/${$store.getters['auth/rrss'].twitter}/`"
            target="blank"
          >
            <v-btn
              fab
              width="10"
              height="30"
              color="primary"
              style="margin-left: 6px !important"
              depressed
              ><v-icon>mdi-twitter</v-icon></v-btn
            ></a
          ><a
            v-if="$store.getters['auth/rrss'].hasOwnProperty('facebook')"
            :href="`https://www.facebook.com/${$store.getters['auth/rrss'].facebook}/`"
            target="blank"
          >
            <v-btn
              fab
              width="10"
              height="30"
              color="primary"
              style="margin-left: 6px !important"
              depressed
              ><v-icon>$facebook</v-icon></v-btn
            ></a
          >
          <a
            v-if="$store.getters['auth/rrss'].hasOwnProperty('tiktok')"
            :href="`https://www.tiktok.com/@${$store.getters['auth/rrss'].tiktok}/`"
            target="blank"
          >
            <v-btn
              fab
              width="10"
              height="30"
              color="primary"
              style="margin-left: 6px !important"
              depressed
              ><v-icon class="ml-1">$tik_tok</v-icon></v-btn
            >
          </a>
        </div>
      </v-list-item>

      <v-list-item
        v-if="!$vuetify.breakpoint.smAndUp"
        style="margin-left: -30px"
      >
        <div class="pl-0">
          <dropdown-language />
        </div>
      </v-list-item>

      <template v-slot:append>
        <div class="caption mx-2 gris1--text">v{{ pjson.version }}</div>
      </template>
      <!--</v-img>-->
    </v-navigation-drawer>
  </div>

  <v-bottom-navigation
    elevation="12"
    color="primary"
    id="mobileDrawer"
    grow
    style="padding-bottom: 70px"
    v-else
  >
    <template v-for="(item, i) in computedItemsMobile">
      <v-btn
        id="bottom_drawer"
        :to="item.to"
        class="rounded-0 no-background-hover"
        :ripple="false"
        text
        :key="i"
      >
        <span class="mt-1">{{ item.title }}</span>
        <v-icon small>{{ item.icon }}</v-icon>
      </v-btn>
    </template>
  </v-bottom-navigation>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({});
</script>
<script>
// Utilities
import { mapState, mapActions } from "vuex";

import constants from "@/constants";
export default {
  name: "CoreDrawer",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DropdownLanguage: () => import("@/components/ui/DropdownLanguage"),
  },
  computed: {
    ...mapState("auth", ["user", "users"]),
    ...mapState("app", ["barColor", "barImage", "drawer", "hover"]),
    drawer: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.$store.commit("app/SET_DRAWER", val);
      },
    },

    hover: {
      get() {
        return this.$store.state.app.hover;
      },
      set(val) {
        this.$store.commit("app/SET_HOVER", val);
      },
    },
    itemsByRole() {
      let array = [
        {
          icon: "$dashboard",
          title: "dashboard.general",
          to: "/",
        },
        {
          icon: "$calendar",
          title: "calendar",
          to: "/calendar",
        },
        {
          icon: "$email",
          title: "consent-form.email",
          to: "/email",
          count: this.$store.getters["email/totalEmails"],
        },
      ];

      switch (this.$store.state.auth.user.role.id) {
        case 1:
          //ADMIN
          array.push({
            icon: "$files",
            title: this.$tc("files", 2),
            to: "/files",
          });
          array.push({
            icon: "$tattooers",
            title: this.$t("artist"),
            to: "/tattooer",
          });

          array.push({
            icon: "$studio",
            title: this.$tc("studio", 2),
            to: "/studios",
          });
          array.push({
            icon: "$customers",
            title: this.$tc("customer", 2),
            to: "/customer",
          });
          array.push({
            icon: "mdi-account-multiple-outline",
            title: this.$tc("user", 2),
            to: "/users",
          });

          array.push({
            icon: "$appointments",
            title: this.$tc("appointment", 2),
            to: "/appointment",
          });
          array.push({
            icon: "$invoice",
            title: this.$t("invoicing"),
            to: "/invoices",
          });

          break;
        case 2:
        case 7:
          //TATTOER
          array.push({
            icon: "$files",
            title: this.$tc("files", 2),
            to: "/files",
          });
          array.push({
            icon: "$studio",
            title: this.$tc("studio", 2),
            to: "/studios",
          });
          array.push({
            icon: "$appointments",
            title: this.$tc("appointment", 2),
            to: "/appointment",
            count:
              this.$store.getters["appointments/inscriptionAppointmentsCount"],
          });
          if (this.$store.state.auth.box_active) {
            array.push({
              icon: "$boxes",
              title: this.$tc("boxes.boxs", 2),
              to: "/box",
            });
          }
          array.push({
            icon: "$customers",
            title: this.$tc("customer", 2),
            to: "/customer",
          });
          if (this.$store.getters["auth/getPlan"]("inventory"))
            array.push({
              icon: "$inventory",
              title: this.$tc("inventory", 2),
              to: "/inventory",
            });
          array.push({
            icon: "$economy",
            title: this.$tc("economy", 2),
            to: "/economy",
          });
          array.push({
            icon: "$invoice",
            title: this.$t("invoicing"),
            to: "/invoices",
          });
          break;
        case 8:
        case 9:
          array.push({
            icon: "$appointments",
            title: this.$tc("appointment", 2),
            to: "/appointment",
            count:
              this.$store.getters["appointments/inscriptionAppointmentsCount"],
          });
          array.push({
            icon: "$customers",
            title: this.$tc("customer", 2),
            to: "/customer",
          });
          array.push({
            icon: "$invoice",
            title: this.$t("invoicing"),
            to: "/invoices",
          });
          break;

        case 3:
        case 5:
          //STUDIO
          array.push({
            icon: "$files",
            title: this.$tc("files", 2),
            to: "/files",
          });
          array.push({
            icon: "$tattooers",
            title: this.$t("artists"),
            to: "/tattooer",
          });

          array.push({
            icon: "$appointments",
            title: this.$tc("appointment", 2),
            to: "/appointment",

            count:
              this.$store.getters["appointments/inscriptionAppointmentsCount"],
          });

          if (
            this.$store.getters["auth/getSetting"]("box_active") == 1 &&
            this.$store.getters["auth/getPlan"]("boxes")
          ) {
            array.push({
              icon: "$boxes",
              title: this.$tc("boxes.boxs", 2),
              to: "/box",
            });
          }

          array.push({
            icon: "$customers",
            title: this.$tc("customer", 2),
            to: "/customer",
          });

        // array.push({
        //   icon: "mdi-chart-sankey",
        //   title: this.$tc("stat", 2),
        //   to: "/stats",
        // });

        case 6:
          if (this.$store.getters["auth/getPlan"]("inventory"))
            array.push({
              icon: "$inventory",
              title: this.$tc("inventory", 2),
              to: "/inventory",
            });
          if (this.$store.getters["auth/getPlan"]("economy"))
            array.push({
              icon: "$economy",
              title: this.$tc("economy", 2),
              to: "/economy",
            });
          array.push({
            icon: "$invoice",
            title: this.$t("invoicing"),
            to: "/invoices",
          });
          break;
        case 4:
          break;
      }

      /*
      array.push({
        icon: "$economy",
        title: this.$tc("invoices", 2),
        to: "/invoices",
      });
      */

      return array;
    },
    computedItems() {
      return this.itemsByRole.map(this.mapItem);
    },
    itemsByRoleMobile() {
      let arrayMobile = [];
      if (
        this.$store.state.auth.user.role.id == 3 ||
        this.$store.state.auth.user.role.id == 5 ||
        this.$store.state.auth.user.role.id == 6 ||
        this.$store.state.auth.user.role.id == 9
      ) {
        arrayMobile = [
          {
            icon: "$dashboard",
            title: "dashboard.general",
            to: "/",
          },
          {
            icon: "$calendar",
            title: "calendar",
            to: "/calendar",
          },
          { icon: "$tattooers", title: "artist", to: "/tattooer" },
          {
            icon: "$appointments",
            title: "Citas",
            to: "/appointment",
          },
          {
            icon: "mdi-view-grid-plus-outline",
            title: "more",
            to: "/more",
          },
        ];
      } else {
        arrayMobile = [
          {
            icon: "$dashboard",
            title: "dashboard.general",
            to: "/",
          },
          {
            icon: "$calendar",
            title: "calendar",
            to: "/calendar",
          },
          { icon: "$studio", title: "Estudios", to: "/studios" },
          {
            icon: "$appointments",
            title: "Citas",
            to: "/appointment",
          },
          {
            icon: "mdi-view-grid-plus-outline",
            title: "more",
            to: "/more",
          },
        ];
      }

      return arrayMobile;
    },
    computedItemsMobile() {
      console.log(this.mapItemMobile);
      return this.itemsByRoleMobile.map(this.mapItem);
    },
  },

  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      //this.$emit("update:expandOnHover", !val);
      this.setDrawer(false);
    },
  },

  mounted() {
    this.pendingAppointments();
    this.getUnreadEmailsCount();
    this.pjson = require("@/../package.json");
  },
  data() {
    return {
      dialog: false,
      pjson: {},
    };
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    ...mapActions("email", ["getUnreadEmails"]),
    getUnreadEmailsCount() {
      this.getUnreadEmails();
    },
    ...mapActions("appointments", ["getPendingAppointments"]),

    pendingAppointments() {
      this.getPendingAppointments();
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'
.v-application--is-ltr #core-navigation-drawer div.v-list-item__icon--text, .v-application--is-ltr #core-navigation-drawer div.v-list-item__icon:first-child

  margin-top: 11px
  margin-bottom: 0px !important

#core-navigation-drawer
  .v-navigation-drawer__append
    background-color: var(--v-gris2-base)

  .v-navigation-drawer__content
    background-color: var(--v-gris2-base)
    height: 100%
    overflow-y: unset
    overflow-x: unset
  .v-responsive__sizer
    display: none
  .v-badge--left .v-badge__badge
    inset: auto calc(100% - 16px) calc(100% - 4px) auto !important
    left: calc(100% - 16px) !important
    right: calc(100% - 16px) !important
  .span
    height: 0px !important
  .v-select-list, .menuable__content__active, .v-menu__content .v-list
    background-color: var(--v-gris4-base) !important
  .v-menu__content
    max-width: 80px !important
    min-width: 75px !important

  .link-documentacio
    background-color: var(--v-gris2-base)
    a
      text-decoration: none
  a.link-documentacio
    text-decoration: none
    .v-list-item__title
      font-size: 13px

@media (max-height: 625px)
  #core-navigation-drawer
    .v-navigation-drawer__content
      overflow-y: auto

#mobileDrawer
  elevation: 100
  margin-top: 0px
  position: fixed
  bottom: 0
  background-color: var(--v-gris2-base)
  z-index: 100

#bottom_drawer
  .v-icon.v-icon
    height: 20px !important
    width: 20px !important
    font-size: 20px !important

.no-background-hover::before
  background-color: transparent !important
</style>
